export const OrdersIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" {...ps}>
    <g clipPath="url(#clip0_1_75)">
      <path d="M10.5 22.5H21V25.5H10.5V22.5ZM10.5 16.5H25.5V19.5H10.5V16.5ZM10.5 10.5H25.5V13.5H10.5V10.5ZM28.5 4.5H22.23C21.6 2.76 19.95 1.5 18 1.5C16.05 1.5 14.4 2.76 13.77 4.5H7.5C7.29 4.5 7.095 4.515 6.9 4.56C6.315 4.68 5.79 4.98 5.385 5.385C5.115 5.655 4.89 5.985 4.74 6.345C4.59 6.69 4.5 7.08 4.5 7.5V28.5C4.5 28.905 4.59 29.31 4.74 29.67C4.89 30.03 5.115 30.345 5.385 30.63C5.79 31.035 6.315 31.335 6.9 31.455C7.095 31.485 7.29 31.5 7.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5V7.5C31.5 5.85 30.15 4.5 28.5 4.5ZM18 4.125C18.615 4.125 19.125 4.635 19.125 5.25C19.125 5.865 18.615 6.375 18 6.375C17.385 6.375 16.875 5.865 16.875 5.25C16.875 4.635 17.385 4.125 18 4.125ZM28.5 28.5H7.5V7.5H28.5V28.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_1_75">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
