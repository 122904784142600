import { Link, useParams } from 'wouter';
import { Api } from '../api';
import { HeadingName } from '../shared/heading-name';
import { useState, useEffect } from 'react';
import { LeftArrowIcon } from '../icons/left-arrow';
import { RightArrowIcon } from '../icons/right-arrow';

export const Workstation = () => {
  const { workstation_id } = useParams<{ workstation_id: string }>();
  const w = Api.read_workstation(workstation_id);

  return (
    <div className="absolute z-10 top-1/3 h-2/3 left-0 right-0 backdrop-blur">
      <div className="flex items-center justify-between border-y-2 border-neutral-500 border-opacity-30 p-6">
        <div className="flex items-center gap-4">
          <ArrowLink id={w.prev_workstation} direction="prev" />
          <HeadingName
            name={`Stazione ${w.id} - ${w.name}`}
            status={w.status}
            started_at={w.started_at}
            size="small"
          />
        </div>
        <ArrowLink id={w.next_workstation} direction="next" />
      </div>
      <div className="p-6 bg-white overflow-auto grid grid-cols-3 gap-4">
        <div>
          <div className="my-3">
            <p className="text-neutral-500 text-sm">Ordine corrente</p>
            <p className="text-xl font-bold text-neutral-900">
              {w.current_order}
            </p>
          </div>
          <ExpectedTime end_by={w.etc} />
          <div className="my-3">
            <p className="text-neutral-500 text-sm">
              Codice articolo • Descrizione articolo
            </p>
            <p className="text-xl font-bold text-neutral-900 truncate overflow-hidden">
              {w.item} • {w.description}
            </p>
          </div>
          <div className="my-3">
            <p className="text-neutral-500 text-sm">Programma saldatura</p>
            <p className="text-xl font-bold text-neutral-900">{w.program}</p>
          </div>
          <hr className="opacity-20 border-black" />
          <div className="my-3">
            <p className="text-neutral-500 text-sm">Ordine seguente</p>
            <p className="text-xl font-bold text-neutral-500">{w.next_order}</p>
          </div>
          <hr className="opacity-20 border-black" />
        </div>
        <div>
          <div className="flex items-start justify-between gap-2 py-2 my-4 border-b-2 border-slate-500 border-opacity-20">
            <span>OEE Stazione</span>
            <span className="text-orange-500 text-6xl font-bold truncate overflow-hidden">
              {(w.oee * 100).toFixed(0)}
              <span className="text-4xl font-light">%</span>
            </span>
          </div>
          <div className="flex items-start justify-between gap-2 py-2 my-4 border-b-2 border-slate-500 border-opacity-20">
            <span className="text-neutral-500">
              Consumo <span className="font-light text-sm">(kWh)</span>
            </span>
            <div className="flex gap-2 items-end">
              <span className="text-6xl font-bold">{w.energy_consumption}</span>
            </div>
          </div>
        </div>
        <div>
          <WorkstationParameter label="Parametro 1" unit="Udm" value={'V1'} />
          <WorkstationParameter label="Parametro 2" unit="Udm" value={'V2'} />
          <WorkstationParameter label="Parametro 3" unit="Udm" value={'V3'} />
          <WorkstationParameter label="Parametro 4" unit="Udm" value={'V4'} />
          <WorkstationParameter label="Parametro 5" unit="Udm" value={'V5'} />
        </div>
      </div>
    </div>
  );
};

const ArrowLink = (ps: { id: string; direction: 'prev' | 'next' }) => {
  const styles =
    'w-8 h-8 fill-transparent hover:scale-110 transition-transform';
  return (
    <Link href={`/workstations/${ps.id}`}>
      {ps.direction === 'prev' ? (
        <LeftArrowIcon className={styles} />
      ) : (
        <RightArrowIcon className={styles} />
      )}
    </Link>
  );
};

const WorkstationParameter = (ps: {
  label: string;
  unit: string;
  value: string;
}) => (
  <div className="flex items-start justify-between gap-2 py-2 my-4 border-b-2 border-slate-500 border-opacity-20">
    <span>
      {ps.label}
      <span className="text-neutral-500 ml-1 text-xs">({ps.unit})</span>
    </span>
    <span className="text-6xl font-bold truncate overflow-hidden">
      {ps.value}
    </span>
  </div>
);

const ExpectedTime = (ps: { end_by: number }) => {
  const [time, setTime] = useState<number>(ps.end_by);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => prev - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex flex-col">
      <span className="text-neutral-500 text-sm">
        Tempo atteso completamento
      </span>
      <span className="font-bold text-6xl">
        {new Date(time).toISOString().substr(11, 8)}
      </span>
    </div>
  );
};
