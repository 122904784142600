import { WorkStatus } from '../api';

export const bg_color = (status: WorkStatus) => {
  if (status === 'running') return 'bg-green-500';
  if (status === 'error') return 'bg-red-500';
  if (status === 'warning') return 'bg-yellow-500';
  return 'bg-gray-500';
};

export const gradient_color = (status: WorkStatus) => {
  if (status === 'running') return 'bg-gradient-to-b from-green-500 to-white';
  if (status === 'error') return 'bg-gradient-to-b from-red-500 to-white';
  if (status === 'warning') return 'bg-gradient-to-b from-yellow-500 to-white';
  return 'bg-gradient-to-b from-gray-500 to-white';
};

export const text_color = (status: WorkStatus) => {
  if (status === 'running') return 'text-green-500';
  if (status === 'error') return 'text-red-500';
  if (status === 'warning') return 'text-yellow-500';
  return 'text-gray-500';
};

export const border_color = (status: WorkStatus) => {
  if (status === 'running') return 'border-green-200';
  if (status === 'error') return 'border-red-200';
  if (status === 'warning') return 'border-yellow-200';
  return 'border-gray-200';
};
