export const Modal = (ps: { onClose(): void; children: React.ReactNode }) => {
  return (
    <div
      className="fixed z-50 top-0 bottom-0 inset-0 bg-neutral-500 backdrop-blur bg-opacity-50 flex items-center justify-center"
      onClick={ps.onClose}>
      <div
        className="w-4/5 h-5/6 bg-white rounded-lg"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        {ps.children}
      </div>
    </div>
  );
};
