export const PencilIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg width="27" height="27" viewBox="0 0 27 27" {...ps}>
    <g clipPath="url(#clip0_1_625)">
      <g clipPath="url(#clip1_1_625)">
        <path d="M6.5 18.375V21.5H9.625L18.8417 12.2833L15.7167 9.15833L6.5 18.375ZM21.8417 9.28333L18.7167 6.15833L16.6083 8.27499L19.7333 11.4L21.8417 9.28333Z" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_625">
        <rect width="27" height="27" rx="4" fill="white" />
      </clipPath>
      <clipPath id="clip1_1_625">
        <rect width="20" height="20" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
