import clsx from 'clsx';
import { WorkStatus } from '../api';
import { bg_color, border_color, text_color } from './colors';
import { Timer } from './timer';

export const HeadingName = (ps: {
  name: string;
  status: WorkStatus;
  started_at?: number;
  size?: 'small' | 'large';
}) => {
  return (
    <div className="flex items-start gap-2">
      {ps.size !== 'small' && (
        <span
          className={clsx(
            'rounded-full w-8 h-8 block border-8',
            bg_color(ps.status),
            border_color(ps.status),
          )}
        />
      )}
      <div>
        <h1 className="font-extrabold uppercase text-2xl">{ps.name}</h1>

        <p
          className={clsx(
            'font-bold text-sm flex items-center gap-2',
            text_color(ps.status),
          )}>
          {ps.size === 'small' && (
            <span
              className={clsx(
                'rounded-full w-4 h-4 block border-4',
                bg_color(ps.status),
                border_color(ps.status),
              )}
            />
          )}
          <span>{status_to_label(ps.status)}</span>
          {ps.started_at && (
            <>
              <span>•</span>
              <Timer start={ps.started_at} />
            </>
          )}
        </p>
      </div>
    </div>
  );
};

const status_to_label = (status: WorkStatus) => {
  if (status === 'running') return 'In Lavorazione';
  if (status === 'error') return 'Errore';
  if (status === 'warning') return 'Attenzione';
  return 'In attesa';
};
