import clsx from 'clsx';
import { Api, Order } from '../api';
import { useState } from 'react';
import { Link } from 'wouter';
import { format_date, format_duration } from '../shared/formatters';
import { AddOrderIcon } from '../icons/add-order';
import { TickIcon } from '../icons/tick';
import { FilterIcon } from '../icons/filter';
import { OrdersIcon } from '../icons/orders';
import { ExitIcon } from '../icons/exit';
import { PencilIcon } from '../icons/pencil';

export const Orders = () => {
  const orders = Api.list_orders();
  const [globalSearch, setGlobalSearch] = useState<string>('');
  const [search, setSearch] = useState<Record<keyof Order, string>>();
  const [sorting, setSorting] = useState<{ key: keyof Order; asc: boolean }>({
    key: 'id',
    asc: true,
  });

  const filteredOrders = orders
    .filter((order) =>
      Object.values(order)
        .filter((v) => typeof v === 'string')
        .some((v) =>
          String(v).toLowerCase().includes(globalSearch.toLowerCase()),
        ),
    )
    .filter((order) =>
      Object.entries(search || {}).every(([key, value]) =>
        // @ts-ignore
        order[key].toString().toLowerCase().includes(value.toLowerCase()),
      ),
    )
    .sort((a, b) => {
      const ak = String(a[sorting.key]);
      const bk = String(b[sorting.key]);
      return sorting.asc
        ? ak.localeCompare(bk, 'en', { numeric: true })
        : bk.localeCompare(ak, 'en', { numeric: true });
    });

  const activeFiltersCount =
    Object.values(search || {}).filter((v) => v.length > 0).length +
    (globalSearch.length > 0 ? 1 : 0);

  return (
    <div className="px-8 py-4">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-bold flex items-center gap-2">
          <OrdersIcon className="w-8 h-8 fill-current" />
          Gestione Ordini
        </h1>
        <div className="flex items-center gap-4">
          <span className="text-sm">
            <b>{filteredOrders.length}</b> of {orders.length} ordini
          </span>
          <div
            onClick={() => {
              setSearch(undefined);
              setGlobalSearch('');
            }}
            className={clsx(
              'px-4 py-2 rounded-md text-sm font-bold flex items-center gap-2',
              activeFiltersCount > 0
                ? 'cursor-pointer bg-orange-300'
                : 'bg-neutral-300 text-neutral-700',
            )}>
            <FilterIcon className="w-5 h-5" /> Cancella filtri (
            {activeFiltersCount})
          </div>
          <Search value={globalSearch} onSearch={setGlobalSearch} />
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left py-2 px-1 bg-neutral-300 first:rounded-l-md last:rounded-r-md" />
            {COLS.map((col) => (
              <th
                key={col.key}
                className="text-left py-2 px-1 text-sm bg-neutral-300 first:rounded-l-md last:rounded-r-md align-top"
                onClick={() => {
                  if (col.sortable) {
                    setSorting({
                      key: col.key,
                      asc: sorting.key === col.key ? !sorting.asc : true,
                    });
                  }
                }}>
                <div className="flex items-center justify-between mb-1 cursor-pointer">
                  {col.label}
                  {col.sortable && (
                    <button className="text-xs text-gray-500 flex flex-col gap-0">
                      <span
                        className={clsx(
                          'inline-block leading-[0.5rem] self-end',
                          sorting.key === col.key && sorting.asc
                            ? 'text-black'
                            : 'text-neutral-400',
                        )}>
                        ▲
                      </span>
                      <span
                        className={clsx(
                          'inline-block leading-[0.5rem] self-start',
                          sorting.key === col.key && !sorting.asc
                            ? 'text-black'
                            : 'text-neutral-400',
                        )}>
                        ▼
                      </span>
                    </button>
                  )}
                </div>

                {col.searchable && (
                  <div className="relative w-full h-6 bg-red-300">
                    <div className="absolute w-full bg-green-100">
                      <Search
                        size="small"
                        value={search?.[col.key] || ''}
                        onSearch={(v) =>
                          setSearch({
                            ...(search || {}),
                            [col.key]: v,
                          } as Record<keyof Order, string>)
                        }
                      />
                    </div>
                  </div>
                )}
              </th>
            ))}
            <th className="text-left p-2 bg-neutral-300 first:rounded-l-md last:rounded-r-md" />
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <Row key={order.id} order={order} />
          ))}
        </tbody>
      </table>
      <div className="flex gap-4 my-4">
        <div className="cursor-pointer hover:scale-105 transition-all flex items-center gap-4 p-4 text-white bg-blue-500 w-64 rounded-xl font-bold">
          <TickIcon className="w-7 h-7 fill-white border border-white rounded-md p-1" />
          Rivedi Selezionati
        </div>
        <div className="cursor-pointer hover:scale-105 transition-all flex items-center gap-4 p-4 text-white bg-green-500 w-64 rounded-xl font-bold">
          <AddOrderIcon className="w-6 h-6 fill-white" />
          Nuovo Ordine
        </div>
      </div>
    </div>
  );
};

const Row = (ps: { order: Order }) => {
  const [editing, setEditing] = useState(false);
  const cell_colors = clsx(
    'border-b',
    editing
      ? 'bg-orange-100'
      : ps.order.status === 'error'
        ? 'bg-red-100'
        : 'group-even:bg-neutral-100',
  );

  return (
    <tr className="group">
      <td className={cell_colors}>
        <input type="checkbox" className="block m-2" />
      </td>
      {COLS.map((col) => (
        <td
          key={col.key}
          className={clsx(
            'py-5 px-1',
            cell_colors,
            col.truncate &&
              'text-ellipsis max-w-1 overflow-hidden whitespace-nowrap',
          )}>
          {col.cell ? col.cell(ps.order) : ps.order[col.key]}
        </td>
      ))}
      <td className={clsx('px-2', cell_colors)}>
        {editing ? (
          <TickIcon
            className="cursor-pointer bg-orange-500 fill-white rounded-sm p-0.5 h-6 w-6"
            onClick={() => setEditing(false)}
          />
        ) : (
          <PencilIcon
            className="cursor-pointer hover:scale-110 fill-orange-500"
            onClick={() => setEditing(true)}
          />
        )}
      </td>
    </tr>
  );
};

const COLS: {
  key: keyof Order;
  label: React.ReactNode;
  truncate?: boolean;
  searchable: boolean;
  sortable: boolean;
  cell?: (row: Order) => React.ReactNode;
}[] = [
  { key: 'id', label: 'Ordine', searchable: true, sortable: true },
  {
    key: 'status',
    label: 'Status',
    searchable: true,
    sortable: true,
    cell: (row) => <StatusBadge status={row.status} />,
  },
  { key: 'item', label: 'Cod. Articolo', searchable: true, sortable: true },
  {
    key: 'description',
    label: 'Descr. Articolo',
    truncate: true,
    searchable: true,
    sortable: true,
  },
  {
    key: 'program',
    label: 'Progr. Saldatura',
    searchable: true,
    sortable: true,
  },
  { key: 'quantity', label: 'Q.tà Exp.', searchable: false, sortable: false },
  {
    key: 'passed',
    label: (
      <span>
        Buoni <small className="font-light">(%)</small>
      </span>
    ),
    searchable: false,
    sortable: false,
  },
  { key: 'wasted', label: 'Scarti', searchable: false, sortable: false },
  {
    key: 'started_at',
    label: (
      <span>
        Start Time <span className="text-blue-500">(EXP.)</span>
      </span>
    ),
    searchable: false,
    sortable: true,
    cell: (row) => format_date(row.started_at),
  },
  {
    key: 'etc',
    label: 'Elapsed',
    searchable: false,
    sortable: false,
    cell: (row) => format_duration(row.etc),
  },
  {
    key: 'etc',
    label: 'ETC',
    searchable: false,
    sortable: false,
    cell: (row) => format_duration(row.etc),
  },
  {
    key: 'ended_at',
    label: (
      <span>
        End Time <span className="text-blue-500">(EXP.)</span>
      </span>
    ),
    searchable: false,
    sortable: true,
    cell: (row) => {
      if (!row.ended_at) {
        return (
          <span className={clsx(row.status === 'scheduled' && 'text-blue-500')}>
            {format_date(row.started_at + row.etc)}
          </span>
        );
      }
      return format_date(row.ended_at);
    },
  },
];

const Search = (ps: {
  value: string;
  onSearch: (query: string) => void;
  size?: 'small' | 'large';
}) => (
  <input
    value={ps.value}
    onChange={(e) => ps.onSearch(e.target.value)}
    onClick={(e) => e.stopPropagation()}
    placeholder="Cerca..."
    className={clsx(
      'inline border border-gray-300 rounded font-normal',
      ps.size === 'small' ? 'text-xs p-1 w-full' : 'text-base w-64 px-3 py-2',
    )}
  />
);

const StatusBadge = (ps: { status: string }) => {
  const badge = (
    <span
      className={clsx(
        'text-base font-bold rounded-md px-2 py-1 uppercase border w-fit flex items-center gap-2',
        {
          'bg-green-100 text-green-500 border-green-500': ps.status === 'open',
          'bg-yellow-100 text-yellow-500 border-yellow-500':
            ps.status === 'standby',
          'bg-red-500 text-white border-red-500 cursor-pointer hover:underline':
            ps.status === 'error',
          'bg-gray-100 text-gray-500 border-gray-500': ps.status === 'closed',
          'bg-blue-100 text-blue-500 border-blue-500':
            ps.status === 'scheduled',
        },
      )}>
      {ps.status}{' '}
      {ps.status === 'error' && <ExitIcon className="w-5 h-5 inline-block" />}
    </span>
  );

  if (ps.status === 'error') {
    return <Link href="#">{badge}</Link>;
  }

  return badge;
};
