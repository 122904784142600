export const RightArrowIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg width="30" height="30" viewBox="0 0 30 30" {...ps}>
    <path
      d="M16.125 19.5L20.625 15M20.625 15L16.125 10.5M20.625 15H9.375M1.5 15C1.5 13.2272 1.84919 11.4717 2.52763 9.83377C3.20606 8.19588 4.20047 6.70765 5.45406 5.45406C6.70765 4.20047 8.19588 3.20606 9.83377 2.52763C11.4717 1.84919 13.2272 1.5 15 1.5C16.7728 1.5 18.5283 1.84919 20.1662 2.52763C21.8041 3.20606 23.2924 4.20047 24.5459 5.45406C25.7995 6.70765 26.7939 8.19588 27.4724 9.83377C28.1508 11.4717 28.5 13.2272 28.5 15C28.5 18.5804 27.0777 22.0142 24.5459 24.5459C22.0142 27.0777 18.5804 28.5 15 28.5C11.4196 28.5 7.9858 27.0777 5.45406 24.5459C2.92232 22.0142 1.5 18.5804 1.5 15Z"
      stroke="#777777"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
