import { Sidebar } from './sidebar';
import { LinePreview } from './line-preview';

export const Home = () => {
  return (
    <div
      className="relative h-full w-full flex items-stretch overflow-hidden"
      style={{ minHeight: 'calc(100vh - 90px)' }}>
      <Sidebar />
      <LinePreview />
    </div>
  );
};
