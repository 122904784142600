export const HistoryIcon = (ps: React.SVGAttributes<SVGElement>) => (
  <svg width="36" height="36" viewBox="0 0 36 36" {...ps}>
    <g clipPath="url(#clip0_1_81)">
      <path d="M19.5 4.5C12.045 4.5 6 10.545 6 18H1.5L7.335 23.835L7.44 24.045L13.5 18H9C9 12.195 13.695 7.5 19.5 7.5C25.305 7.5 30 12.195 30 18C30 23.805 25.305 28.5 19.5 28.5C16.605 28.5 13.98 27.315 12.09 25.41L9.96 27.54C12.405 29.985 15.765 31.5 19.5 31.5C26.955 31.5 33 25.455 33 18C33 10.545 26.955 4.5 19.5 4.5ZM18 12V19.5L24.375 23.28L25.53 21.345L20.25 18.21V12H18Z" />
    </g>
    <defs>
      <clipPath id="clip0_1_81">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
