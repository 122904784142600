import clsx from 'clsx';
import { Api } from '../api';
import { Link, Route, Switch, useRoute } from 'wouter';
import { Workstation } from './workstation';
import { bg_color, border_color, gradient_color } from '../shared/colors';
import { ResizeIcon } from '../icons/resize';

export const LinePreview = () => {
  const line = Api.current_line();
  const [, rest] = useRoute<{ workstation_id: string }>(
    '/workstations/:workstation_id',
  );

  return (
    <div
      className="relative w-full p-6 bg-no-repeat bg-center bg-contain"
      style={{
        backgroundImage: `url(/line.png)`,
      }}>
      <Link href="/">
        <div className="absolute border border-neutral-500 border-opacity-50 px-4 py-2 bg-white text-sm text-neutral-400 rounded-lg flex items-center gap-2 hover:scale-105 transition-all">
          <ResizeIcon className="fill-neutral-500" /> Reset View
        </div>
      </Link>
      {line.workstations.map((ws) => {
        const active = rest?.workstation_id === ws.id;
        return (
          <Link key={ws.id} href={`/workstations/${ws.id}`}>
            <div
              className={clsx(
                'group w-8 h-8 rounded-full absolute cursor-pointer transition-transform text-center',
                active ? 'scale-110' : 'hover:scale-110',
                bg_color(ws.status),
              )}
              style={{ left: `${ws.position[0]}%`, top: `${ws.position[1]}%` }}>
              <span
                className={clsx(
                  'absolute top-full mt-2 w-1 h-8 -translate-x-0.5 rounded-full',
                  gradient_color(ws.status),
                )}
              />
              <span
                className={clsx(
                  active ? 'inline' : 'hidden group-hover:inline',
                  'absolute top-full mt-10 -translate-x-32 w-64 h-64 bg-opacity-10 border-4 rounded-full',
                  bg_color(ws.status),
                  border_color(ws.status),
                )}
              />
            </div>
          </Link>
        );
      })}
      <Switch>
        <Route path="/workstations/:workstation_id" component={Workstation} />
      </Switch>
    </div>
  );
};
