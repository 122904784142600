import { Route, Switch } from 'wouter';
import { MainLayout } from './shared/main-layout';
import { Home } from './home';
import { Orders } from './orders';

function App() {
  return (
    <MainLayout>
      <Switch>
        <Route path="/orders" component={Orders} />
        <Route path="/historic-data" component={() => <div />} />
        <Route path="*" component={Home} />
      </Switch>
    </MainLayout>
  );
}

export default App;
