import { useState, useEffect } from 'react';

export const Timer = (ps: { start: number }) => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now() - ps.start);
    }, 1000);
    return () => clearInterval(interval);
  }, [ps.start]);
  return <span>{new Date(time).toISOString().substr(11, 8)}</span>;
};
