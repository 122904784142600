import { Header } from './header';

export const MainLayout = (ps: { children: React.ReactNode }) => {
  return (
    <div>
      <div className="h-[90px]">
        <Header />
      </div>
      <main
        className="overflow-auto"
        style={{ minHeight: 'calc(100vh - 90px)' }}>
        {ps.children}
      </main>
    </div>
  );
};
