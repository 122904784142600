import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Api } from '../api';
import { Link } from 'wouter';
import { OrdersIcon } from '../icons/orders';
import { IronIcon } from '../icons/iron';
import { HistoryIcon } from '../icons/history';

export const Sidebar = () => {
  const order = Api.current_order();
  return (
    <div className="w-1/4 py-4 px-8 bg-black">
      <h2 className="text-orange-500 text-xl font-bold">Attività Linea</h2>
      <OrderValue label="Ordine corrente" value={order.id} />
      <OrderValue
        label="Codice articolo • Descrizione articolo"
        value={`${order.item} • ${order.description}`}
      />
      <OrderValue label="Programma saldatura" value={order.program} />
      <ExpectedTime end_by={order.etc} />
      <OrderValue label="Ordine seguente" value={order.next_order} />
      <hr className="opacity-20" />
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-2 my-4">
          <span className="text-neutral-500">Buoni / Previsti</span>
          <div className="flex gap-2 items-end">
            <span className="text-green-500 text-5xl font-bold">
              {order.passed}
            </span>
            <span className="text-neutral-500 font-thin text-4xl">/</span>
            <span className="text-white text-3xl font-bold">
              {order.quantity}
            </span>
          </div>
          <span>
            <span className="text-green-500">
              {((order.passed / order.quantity) * 100).toFixed(0)}%
            </span>
            <span className="text-neutral-500 ml-1">completamento</span>
          </span>
        </div>
        <div className="flex flex-col gap-2 my-4 text-right">
          <span className="text-neutral-500">Scarti</span>
          <div className="flex gap-2 items-end">
            <span className="text-red-500 text-5xl font-bold">
              {order.wasted}
            </span>
          </div>
        </div>
      </div>
      <div className="mb-4 rounded-full bg-neutral-600 h-4 w-full">
        <div
          className="rounded-full bg-green-500 h-full"
          style={{ width: `${(order.passed / order.quantity) * 100}%` }}
        />
      </div>
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-2 mb-4">
          <span className="text-neutral-500">OEE</span>
          <div className="flex gap-2 items-end">
            <span className="text-green-500 text-5xl font-bold">
              {(order.oee * 100).toFixed(0)}
              <span className="text-4xl font-light">%</span>
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-4 text-right">
          <span className="text-neutral-500">
            Consumo <span className="font-light text-sm">(kWh)</span>
          </span>
          <div className="flex gap-2 items-end">
            <span className="text-white text-5xl font-bold">
              {order.energy_consumption}
            </span>
          </div>
        </div>
      </div>
      <hr className="opacity-20" />
      <div className="flex justify-between gap-2 my-4">
        <div className="flex flex-col text-neutral-500">
          <span className="">Warning / Errori</span>
          <span className="underline my-2 text-sm font-light cursor-pointer">
            Espandi
          </span>
        </div>
        <div className="flex gap-2 items-center text-5xl">
          <span className="text-orange-500 font-bold">{order.warnings}</span>
          <span className="text-neutral-500 font-thin">/</span>
          <span className="text-red-500 font-bold">{order.errors}</span>
        </div>
      </div>
      <div className="w-full flex items-center gap-2 mt-12">
        <ButtonLink
          className="w-1/3 bg-blue-500"
          label="Gestione Ordini"
          icon={<OrdersIcon className="w-8 h-8 fill-white" />}
          to="/orders"
        />
        <ButtonLink
          className="w-1/3 bg-orange-500"
          icon={<IronIcon className="w-8 h-8 fill-white" />}
          label="Controllo Saldatura"
          to="/"
        />
        <ButtonLink
          className="w-1/3 bg-neutral-700"
          icon={<HistoryIcon className="w-8 h-8 fill-white" />}
          label="Storico Dati"
          to="/historic-data"
        />
      </div>
    </div>
  );
};

const OrderValue = (ps: { label: string; value: string }) => (
  <div className="flex flex-col gap-2 my-4">
    <span className="text-neutral-500 text-sm">{ps.label}</span>
    <span className="text-white text-lg font-bold truncate overflow-hidden">
      {ps.value}
    </span>
  </div>
);

const ExpectedTime = (ps: { end_by: number }) => {
  const [time, setTime] = useState<number>(ps.end_by);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => prev - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className="flex items-center justify-between my-4">
      <span className="text-neutral-500 text-sm capitalize">
        Tempo atteso completamento
      </span>
      <span className="text-green-500 font-bold text-4xl">
        {new Date(time).toISOString().substr(11, 8)}
      </span>
    </div>
  );
};

const ButtonLink = (ps: {
  label: string;
  icon: React.ReactNode;
  to: string;
  className?: string;
}) => (
  <Link
    className={clsx(
      'text-white p-3 rounded-xl text-left text-sm flex flex-col font-semibold leading-tight shadow-md shadow-neutral-800',
      'hover:shadow-none hover:scale-105 transition-transform',
      ps.className,
    )}
    href={ps.to || '/'}>
    <span className="mb-4">{ps.icon}</span>
    {ps.label.split(' ').map((word, i) => (
      <span key={i}>{word}</span>
    ))}
  </Link>
);
