import { useState } from 'react';
import { Api } from '../api';
import { SettingsIcon } from '../icons/settings';
import { UserIcon } from '../icons/user';
import { Timer } from './timer';
import { Modal } from './modal';

export const UserPreview = () => {
  const user = Api.current_user();
  const [settings, setSettings] = useState(false);

  return (
    <div className="flex items-center gap-4">
      <div className="flex flex-col text-right">
        <h1 className="font-bold text-xl">{user.name}</h1>
        <p className="text-neutral-500 text-sm font-bold flex items-center justify-end gap-2">
          <span className="capitalize font-normal">{user.role}</span>
          <span>•</span>
          <span className="text-green-500">
            <Timer start={user.started_at} />
          </span>
        </p>
      </div>
      <UserIcon className="w-12 h-12 rounded-full fill-green-500" />
      {user.is_admin && (
        <SettingsIcon
          className="w-8 h-8 rounded-full fill-neutral-500 hover:scale-110 transition-all cursor-pointer"
          onClick={() => setSettings(true)}
        />
      )}
      {settings && (
        <Modal onClose={() => setSettings(false)}>
          <div
            className="w-full h-full p-4 rounded-lg border-2 border-neutral-500"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <h1 className="text-xl font-bold">Settings Modal</h1>
          </div>
        </Modal>
      )}
    </div>
  );
};
